<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-h2 text-left font-weight-bold"
                            style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                            Perspectives
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light"
                            style="line-height: 40px !important;">
                            <!-- Streamline Your Geospatial Data Pipeline<br>Process, Analyze, and Visualize Geospatial Data with Ease -->
                            Shaping Views, Broadening Horizons: Dive into Our Perspectives.
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <!-- <div class="video-background">
                <span>
                    <video muted="" autoplay="" playsinline="" loop="" poster="">
                        <source src="../assets/images/bg/marine-slider-vid.mp4" />
                    </video>
                </span>
            </div> -->

            <div class="photo-background">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                    <img src="../assets/images/bg/silhouette-people-city.jpg" alt=""
                        style="background-repeat: no-repeat; background-size: cover; background-position: center center;" />
                </span>
            </div>
        </div>
        <!-- End Slider Area -->
        <v-toolbar color="#dd8732" class="toolbar-container" ref="toolbar">
            <v-row>
                <v-col></v-col>
                <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                    <v-text-field class="customTextField" placeholder="Search By Keyword" dense hide-details
                        v-model="filterParameters">
                        <template v-slot:append-outer>
                            <v-btn block style="background-color: rgb(21, 41, 57)" v-on:click="filterPerspective()" elevation="0">
                                <span style="color: white;">Filter</span>
                            </v-btn>
                        </template>
                        <template v-slot:append>
                            <v-btn icon v-on:click="resetFilterPerspective">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-toolbar>
        <!-- <v-container fluid style="min-height: 10vh; padding: 100px;" class="bg_color--5">
            <v-row>
                <v-col md="12" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsHighlighted"
                    style="display:flex; justify-content: center; align-items: center; padding: 20px;">
                    <v-img :src="item.image" width="auto" height="470px" style="z-index: 0;">
                        <v-card v-on:click=""
                            style="position:absolute; height: 100%; width: 50%; bottom:0%; z-index: 1; opacity: 0.7;" flat
                            tile class="highlighted-mobile">
                            <v-card-title>
                                <h4>
                                    <strong>Highlighted Perspective</strong>
                                </h4>
                            </v-card-title>
                            <v-card-text>
                                <h2>
                                    <strong>{{ item.title }}</strong>
                                </h2>
                                <strong>
                                    {{ item.description }}
                                </strong>
                            </v-card-text>
                        </v-card>
                    </v-img>

                </v-col>
            </v-row>

            <v-row>
                <v-col md="12" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsHighlighted">
                    <div class="container-article">
                        <v-img :src="item.image" class="image-article">
                            <v-card :href="item.link" target="_blank"
                                class="highlighted-mobile d-flex align-end card-article pb-2 pl-6" flat tile>
                                <v-card-title
                                    style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;">
                                    <span class="source-title">{{ item.source }}</span>
                                    <h3 class="heading-title2"> {{ item.title }} </h3>
                                </v-card-title>
                            </v-card>
                            <div class="overlay-article"></div>
                        </v-img>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="4" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsUnhighlighted"
                    style="display:flex; justify-content: center; align-items: center; padding: 20px;">
                    <v-img :src="item.image" width="auto" height="470px" style="z-index: 0;">
                        <v-card v-on:click="" style="position:absolute; width: auto; bottom:0%; z-index: 1; opacity: 0.7;"
                            flat tile>
                            <v-card-title>
                                <h4>
                                    <strong>{{ item.title }}</strong>
                                </h4>
                            </v-card-title>
                            <v-card-text>
                                <strong>
                                    {{ item.description }}
                                </strong>
                            </v-card-text>
                        </v-card>
                    </v-img>

                </v-col>
            </v-row>
        </v-container> -->
        <div style="min-height: 10vh; padding: 100px;" class="bg_color--5">
            <v-container>
                <v-row>
                    <v-col md="12" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsHighlighted">
                        {{ item.image }} {{ index }}
                        <div class="container-article">
                            <v-img :src="item.image" class="image-article">
                                <v-card :href="item.link" target="_blank"
                                    class="highlighted-mobile d-flex align-end card-article pb-2 pl-6" flat tile>
                                    <v-card-title
                                        style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;">
                                        <span class="source-title">{{ item.source }}</span>
                                        <h3 class="heading-title2"> {{ item.title }} </h3>
                                    </v-card-title>
                                </v-card>
                                <div class="overlay-article"></div>
                            </v-img>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsUnhighlighted">
                        {{ item.image }} {{ index }}
                        <div class="container-article">
                            <v-img :src="item.image" class="image-article2">
                                <v-card :href="item.link" target="_blank" class="card-article2" flat tile>
                                    <v-card-title
                                        style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;">
                                        <span class="source-title" style="font-size: 18px;">{{ item.source }}</span>
                                        <h5> {{ item.title }} </h5>
                                    </v-card-title>
                                    <v-card-text>
                                        <!-- <strong>
                                            {{ item.description }}
                                        </strong> -->
                                    </v-card-text>
                                </v-card>
                                <div class="overlay-article"></div>
                            </v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- Start Content -->
        <!-- <v-tabs-items v-model="tabContent">
            <v-tab-item>
                <SAP />
            </v-tab-item>
            <v-tab-item>
                <PersonalFinancialManagement />
            </v-tab-item>
            <v-tab-item>
                <OperationalDataStoreMartVue />
            </v-tab-item>
        </v-tabs-items> -->
        <!-- End Content -->
        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";

// import Datapipe from './Datapipe.vue'
import SAP from "./SAP.vue";
import OperationalDataStoreMartVue from './LimitManagementSystem.vue';
import PersonalFinancialManagement from './PersonalFinancialManagement.vue';

export default {
    components: {
        SAP,
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        OperationalDataStoreMartVue,
        PersonalFinancialManagement,
        Footer,
    },
    methods: {
        findImage() {
            const url = "https://finance.detik.com/berita-ekonomi-bisnis/d-6317590/bank-ramai-ramai-mulai-manfaatkan-robot-buat-pelayanan";

            // Send an HTTP GET request to the URL
            fetch(url)
                .then(response => response.text())
                .then(html => {
                    console.log(html)
                    // Create a DOMParser
                    const parser = new DOMParser();

                    // Parse the HTML content of the page
                    const doc = parser.parseFromString(html, "text/html");

                    // Find the image element (change 'img' selector as needed)
                    const imageElement = doc.querySelector('img.detail__img');

                    // Extract the image URL
                    if (imageElement) {
                        const imageUrl = imageElement.getAttribute('src');
                        console.log("Image URL:", imageUrl);

                        // Now, you can embed the image on your website using imageUrl
                        // Example: document.getElementById('imageContainer').innerHTML = `<img src="${imageUrl}" alt="Description of the image">`;
                    } else {
                        console.log("Image not found on the page.");
                    }
                })
                .catch(error => {
                    console.error("Error fetching the webpage:", error);
                });
        },
        iconSvg(icon) {
            return feather.icons[icon].toSvg()
        },
        filterPerspective() {
            let filter = this.perspectiveContents.filter(e => e.title.toLowerCase().includes(this.filterParameters.toLowerCase()))

            console.log(this.perspectiveContents, filter)

            this.perspectiveContentsFiltered = filter
        },
        resetFilterPerspective() {
            console.log(this.perspectiveContentsFiltered, this.perspectiveContents)
            this.perspectiveContentsFiltered = this.perspectiveContents
            this.filterParameters = ''
        }
    },
    computed: {
        perspectiveContentsHighlighted() {
            return [this.perspectiveContentsFiltered[0]]
        },
        perspectiveContentsUnhighlighted() {
            return this.perspectiveContentsFiltered.filter((e, index) => index != 0)
        }
    },
    mounted() {
        this.perspectiveContentsFiltered = this.perspectiveContents
        // this.findImage()
    },
    data() {
        return {
            filterParameters: '',
            perspectiveContentsFiltered: [],

            perspectiveContents: [
                // {
                //     source: 'Detik Finance',
                //     title: 'Bank Ramai-ramai Mulai Manfaatkan \'Robot\' buat Pelayanan',
                //     date: '28/09/2022',
                //     link: 'https://finance.detik.com/berita-ekonomi-bisnis/d-6317590/bank-ramai-ramai-mulai-manfaatkan-robot-buat-pelayanan',
                //     image: require('../assets/images/service/news/logo-detikfinance.png'),
                // },
                {
                    source: 'Media Indonesia',
                    title: 'Perbankan Berlomba Tingkatkan Layanan Lewat Digitalisasi dan Kecerdasan Buatan',
                    date: '28/09/2022',
                    link: 'https://m.mediaindonesia.com/ekonomi/526090/perbankan-berlomba-tingkatkan-layanan-lewat-digitalisasi-dan-kecerdasan-buatan',
                    // image: require('../assets/images/service/news/mediaindonesia.png'),
                    image: require('../assets/images/service/perspective_pictures/media_indonesia.jpg'),
                },
                {
                    source: 'Katadata',
                    title: 'Teknologi di Balik Livin’ Bank Mandiri: Atasi Ratusan Juta Transaksi',
                    date: '28/09/2022',
                    link: 'https://katadata.co.id/desysetyowati/digital/63342a5705ada/teknologi-di-balik-livin-bank-mandiri-atasi-ratusan-juta-transaksi',
                    image: require('../assets/images/service/news/Katadata.png'),
                },
                {
                    source: 'Katadata',
                    title: 'Perbankan Tingkatkan Pelayanan Melalui Digital dan AI',
                    date: '28/09/2022',
                    link: 'https://katadata.co.id/doddyrosadi/berita/6333fe3aa8eb1/perbankan-tingkatkan-pelayanan-melalui-digital-dan-ai',
                    image: require('../assets/images/service/news/Katadata.png'),
                },
                {
                    source: 'Antara News',
                    title: 'Perbankan Mendorong Nasabah berubah dari Konvensional ke Digital',
                    date: '28/09/2022',
                    link: 'https://mataram.antaranews.com/berita/221553/perbankan-mendorong-nasabah-berubah-dari-konvensional-ke-digital',
                    image: require('../assets/images/service/news/Logo-ANTARANews.png'),
                },
                {
                    source: 'Marketing.co.id',
                    title: 'Begini Cara Bank Himbara Meningkatkan Pelayanan di Era Digital',
                    date: '29/09/2022',
                    link: 'https://www.marketing.co.id/perbankan-tingkatkan-pelayanan-melalui-digitalisasi-dan-artificial-intelligence/',
                    image: require('../assets/images/service/news/logomarketingcoid.jpg'),
                },
                // {
                //     source: 'Kompas',
                //     title: 'Pemanfaatan Data "Real Time" Mampu Tingkatkan Daya Saing Perusahaan',
                //     date: '30/09/2022',
                //     link: 'https://money.kompas.com/read/2022/09/30/070000126/pemanfaatan-data-real-time-mampu-tingkatkan-daya-saing-perusahaan',
                //     image: require('../assets/images/service/news/kompas.jpg')
                // },
                {
                    source: 'Medcom',
                    title: 'Ingat! Perusahaan Butuh Active Intelligence untuk Tingkatkan Daya Saing',
                    date: '29/09/2022',
                    link: 'https://www.medcom.id/ekonomi/bisnis/GKd2EX0b-ingat-perusahaan-butuh-active-intelligence-untuk-tingkatkan-daya-saing',
                    image: require('../assets/images/service/news/Medcom_(2019).png')
                },
                {
                    source: 'Kontan',
                    title: 'Persaingan Meningkat, Perusahaan Bisa Menerapkan Active Intelligence',
                    date: '30/09/2022',
                    link: 'https://industri.kontan.co.id/news/persaingan-meningkat-perusahaan-bisa-menerapkan-active-intelligence',
                    image: require('../assets/images/service/news/kontan.png')
                },
                {
                    source: 'Investor.id',
                    title: 'Active Intelligence Dinilai Mampu Tingkatkan Daya Saing Perusahaan',
                    date: '1/10/2022',
                    link: 'https://investor.id/it-and-telecommunication/308605/active-intelligence-dinilai-mampu-tingkatkan-daya-saing-perusahaan',
                    image: require('../assets/images/service/news/investorLogo.jpg')
                },
                {
                    source: 'Republika',
                    title: 'Dukung Transformasi, Perbankan Dorong Nasabah Berubah dari Konvensional ke Digital',
                    date: '28/09/2022',
                    link: 'https://ekonomi.republika.co.id/berita//rixfxv456/dukung-transformasi-perbankan-dorong-nasabah-berubah-dari-konvensional-ke-digital',
                    image: require('../assets/images/service/news/logo-republika.jpg')
                },
                // {
                //     source: 'Jawapos',
                //     title: 'Upaya Pacu Daya Saing Perusahaan Lewat Pemanfaatan Active Intelligence ',
                //     date: ' 29/09/2022',
                //     link: 'https://www.jawapos.com/teknologi/01410701/upaya-pacu-daya-saing-perusahaan-lewat-pemanfaatan-active-intelligence',
                //     image: require('../assets/images/service/news/jawapos.png')
                // },
                {
                    source: 'Qlik (logo qlik)',
                    title: 'Qlik Announces Recipients of its Global and Regional Partner Awards',
                    date: '12/05/2021',
                    link: 'https://www.qlik.com/blog/qlik-announces-recipients-of-its-global-and-regional-partner-awards',
                    image: require('../assets/images/service/principal/qlik.png')
                },
                {
                    source: 'Antara',
                    title: 'Kemenhub luncurkan sistem integrasi kenavigasian I-Motion',
                    date: '1/12/2021',
                    link: 'https://www.antaranews.com/berita/2558921/kemenhub-luncurkan-sistem-integrasi-kenavigasian-i-motion',
                    image: require('../assets/images/service/news/Logo-ANTARANews.png')
                }
            ],

            dummyPerspectiveContents: [
                {
                    title: 'Content1 content1',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content2 content2',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content3 content3',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content4 content4',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content5 content5',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content6 content6',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content7 content7',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content8 content8',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
                {
                    title: 'Content9 content9',
                    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem fugit ipsum eligendi quia dolorem ea sapiente cum accusantium exercitationem ducimus veritatis eveniet quaerat corrupti officiis tempora rerum voluptatem, dignissimos rem!',
                    image: require("../assets/images/about/finding-us-01.jpg"),
                },
            ],
        };
    },
    watch: {
        // 
    }
};
</script>
<style scoped>
.heading-title1 {
    color: #e8ebf0 !important;
    line-height: 61px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
    color: #1f1f25 !important;
    font-size: 42px;
    font-family: 'Urbanist', sans-serif !important;
}

.source-title {
    color: #dd8732 !important;
    letter-spacing: 5px;
    font-family: 'Outfit', sans-serif !important;
}

.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;

}

.image-article {
    z-index: 0;
    border-radius: 5px !important;
    /* display: block; */
    width: auto;
    height: 470px;
}

.image-article2 {
    z-index: 0;
    border-radius: 5px !important;
    height: 270px;
}

.card-article {
    position: absolute;
    height: 100%;
    width: 50%;
    bottom: 0%;
    z-index: 1;
    opacity: 0.9;
}

.card-article2 {
    position: absolute;
    width: auto;
    height: 50%;
    bottom: 0%;
    z-index: 1;
    opacity: 0.9;
}

.overlay-article {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #eeeeee;
}

.container-article {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.container-article:hover .overlay-article {
    opacity: 0.7;

}

.container-article:hover .card-article {
    opacity: 0.7;
    transition: .5s ease;
    background-color: #3b4950;
}

.container-article:hover .card-article2 {
    opacity: 0.5;
    transition: .5s ease;
    background-color: #3b4950;
}

.card-article:hover {
    transition: .5s ease;
    color: #e8ebf0 !important;
}

.card-article2:hover {
    transition: .5s ease;
    color: #e8ebf0 !important;
}


.bg-feature {
    background-image: linear-gradient(45deg, #232526, #414345);
}

.center-items-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-tab {
    color: black;
}

.customTextField ::placeholder {
    font-size: 20px;
    color: black !important;
}


@media screen and (max-width: 767px) {
    .highlighted-mobile {
        width: 100% !important;
        font-size: 20px;
        background-color: #dd8732;
    }
}

/* .heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
    color: #dd8732 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}

.heading-caption2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}

.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;

}

.bg-feature {
    background-image: linear-gradient(45deg, #232526, #414345);
}

.center-items-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-tab {
    color: black;
}

.customTextField ::placeholder {
    font-size: 20px;
    color: black !important;
}


@media screen and (max-width: 767px) {
    .highlighted-mobile {
        width: 100% !important;
        font-size: 20px;
        background-color: #dd8732;
    }
} */
</style>
  